import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Section, Text, Title } from "../../components/Core";

const PolicyChanges = () => (
  <>
    <Section className="pt-0 pb-5">
      <Container>
        <Row className="justify-content-center mb-3">
          <Col>
            <Title variant="quote" className="mb-2">
              Changes to this Privacy Policy
            </Title>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <Text>
              We may update Our Privacy Policy from time to time. We will notify You of any changes
              by posting the new Privacy Policy on this page.
            </Text>
            <Text mt={3}>
              We will let You know via email and/or a prominent notice on Our Service, prior to the
              change becoming effective and update the "Last updated" date at the top of this Privacy Policy.
            </Text>
            <Text mt={3}>
              You are advised to review this Privacy Policy periodically for any changes.
              Changes to this Privacy Policy are effective when they are posted on this page.
            </Text>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default PolicyChanges;
