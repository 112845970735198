import styled from "styled-components";
import { Box } from "../../components/Core";

export const PrivacyList = styled.ul`
  list-style-type: disc;
  li {
    font-size: 21px;
    font-weight: 300;
    margin-top: 6px;
    margin-left: 32px;
  }  
`;

export const Highlight = styled.span`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.dark};
`;

export const IndentedBox = styled(Box)`
  margin-left: 40px;
`;