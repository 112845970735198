import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Section, Text, Title } from "../../components/Core";

const OtherWebsiteLinks = () => (
  <>
    <Section className="pt-0 pb-5">
      <Container>
        <Row className="justify-content-center mb-3">
          <Col>
            <Title variant="quote" className="mb-2">
              Links to Other Websites or Applications
            </Title>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <Text>
              Our Service may contain links to other websites or applications that are not operated by Us.
              If You click on a third-party link, You will be directed to that third-party's site.
              We strongly advise You to review the Privacy Policy of every site You visit.
            </Text>
            <Text mt={3}>
              We have no control over and assume no responsibility for the content, privacy policies
              or practices of any third-party sites or services.
            </Text>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default OtherWebsiteLinks;
