import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Section, Text, Title } from "../../../components/Core";

const CCPARights = () => (
  <>
    <Section className="pt-0 pb-5">
      <Container>
        <Row className="justify-content-center mb-3">
          <Col>
            <Title variant="quote" className="mb-2">
              Your California Privacy Rights<br />(California's Shine the Light law)
            </Title>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <Text>
              Under California Civil Code Section 1798 (California's Shine the Light law),
              California residents with an established business relationship with us can
              request information once a year about sharing their Personal Data with
              third parties for the third parties' direct marketing purposes.
            </Text>
            <Text>
              If you'd like to request more information under the California Shine the Light law,
              and if You are a California resident, You can contact Us using the contact
              information provided below.
            </Text>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default CCPARights;
