/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import imgMetaCardSquare from "../../assets/images/card_homepage_square.jpg";
import imgMetaCardTwitter from "../../assets/images/card_homepage_twitter.jpg";

function Seo({ description, author, authorTwitter, lang, meta, image, twitterImage, title, pathname }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            authorTwitter
            keywords
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaAuthor = author || site.siteMetadata.author
  const metaAuthorTwitter = authorTwitter || site.siteMetadata.authorTwitter
  const defaultTitle = site.siteMetadata?.title
  const metaImage = image
    ? `${site.siteMetadata.siteUrl}${image}`
    : `${site.siteMetadata.siteUrl}${imgMetaCardSquare}`
  const metaImageTwitter = twitterImage
    ? `${site.siteMetadata.siteUrl}${twitterImage}`
    : (image ? metaImage : `${site.siteMetadata.siteUrl}${imgMetaCardTwitter}`)
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null
  const keywords = site.siteMetadata.keywords

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      link={
        canonical
          ? [
            {
              rel: "canonical",
              href: canonical,
            },
          ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `author`,
          content: metaAuthor,
        },
        {
          name: `keywords`,
          content: keywords,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: canonical || ``,
        },
        {
          name: `twitter:site`,
          content: metaAuthorTwitter || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(
        metaImage
          ? [
            {
              property: "og:image",
              content: metaImage,
            },
            {
              name: "twitter:card",
              content: "summary_large_image",
            },
            {
              name: "twitter:image",
              content: metaImageTwitter,
            },
          ]
          : [
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ]
      ).concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  author: PropTypes.string,
  authorTwitter: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  twitterImage: PropTypes.string,
  pathname: PropTypes.string,
}

export default Seo
