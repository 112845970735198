import React from "react";
import { Text, Title } from "../../components/Core";
import { Highlight, IndentedBox, PrivacyList } from "./Common";

const TypesOfDataCollected = () => (
  <>
    <Title variant="card">
      Types of Data Collected
    </Title>
    <Text mt={3}>
      <Highlight>Personal Data</Highlight>
    </Text>
    <Text>
      While using Our Service, We may ask You to provide Us with certain personally identifiable
      information that can be used to contact or identify You.
      Personally identifiable information may include, but is not limited to:
    </Text>
    <IndentedBox>
      <Text mt={4}>
        <Highlight>Usage Data</Highlight>
      </Text>
      <Text>
        Usage Data is collected automatically when using the Service.
      </Text>
      <Text mt={3}>
        Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address),
        the screens of our Service that You visit, the time and date of Your visit,
        the time spent on those screens, and other diagnostic data.
      </Text>
      <Text mt={3}>
        When You access the Service by or through a mobile device, We may collect certain information automatically,
        including, but not limited to, the type of mobile device You use, the IP address of Your mobile device,
        Your mobile operating system, and other diagnostic data.
      </Text>
      <Text mt={4}>
        <Highlight>Information Collected while Using the Application</Highlight>
      </Text>
      <Text>
        While using Our Application, in order to provide features of Our Application,
        We may collect, with Your prior permission:
      </Text>
      <PrivacyList>
        <li>Information regarding your location</li>
        <li>Photographs, document scans and other information from your Device's camera and photo library</li>
      </PrivacyList>
      <Text mt={3}>
        We use this information to provide features of Our Service, and to improve and customize Our Service.
        The information may be uploaded to the Company's servers and/or a Service Provider's server
        or it may be simply stored on Your device.
      </Text>
      <Text mt={3}>
        You can enable or disable access to this information at any time, through Your Device settings.
      </Text>
    </IndentedBox>
  </>
);

export default TypesOfDataCollected;