import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Section, Text, Title } from "../../components/Core";
import { PrivacyList, Highlight } from "./Common";

const GDPR = () => (
  <>
    <Section className="pt-0 pb-5">
      <Container>
        <Row className="justify-content-center mb-3">
          <Col>
            <Title variant="quote" className="mb-2">
              GDPR Privacy
            </Title>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <Title variant="card">
              Legal Basis for Processing Personal Data under GDPR
            </Title>
            <Text>
              We may process Personal Data under the following conditions:
            </Text>
            <PrivacyList>
              <li>
                <Highlight>Consent:</Highlight> You have given Your consent for processing Personal Data
                for one or more specific purposes.
              </li>
              <li>
                <Highlight>Performance of a contract:</Highlight> Provision of Personal Data is necessary
                for the performance of an agreement with You and/or for any pre-contractual obligations thereof.
              </li>
              <li>
                <Highlight>Legal obligations:</Highlight> Processing Personal Data is necessary for compliance
                with a legal obligation to which the Company is subject.
              </li>
              <li>
                <Highlight>Vital interests:</Highlight> Processing Personal Data is necessary in order to
                protect Your vital interests or of another natural person.
              </li>
              <li>
                <Highlight>Public interests:</Highlight> Processing Personal Data is related to a task
                that is carried out in the public interest or in the exercise of official authority vested in the Company.
              </li>
              <li>
                <Highlight>Legitimate interests:</Highlight> Processing Personal Data is necessary for the purposes of
                the legitimate interests pursued by the Company.
              </li>
            </PrivacyList>
            <Text>
              In any case, the Company will gladly help to clarify the specific legal basis that applies to the processing,
              and in particular whether the provision of Personal Data is a statutory or contractual requirement,
              or a requirement necessary to enter into a contract.
            </Text>
            <Title variant="card" mt={4}>
              Your Rights under the GDPR
            </Title>
            <Text>
              The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can exercise Your rights.
            </Text>
            <Text mt={3}>
              You have the right under this Privacy Policy, and by law if You are within the EU, to:
            </Text>
            <PrivacyList>
              <li>
                <Highlight>Request access to Your Personal Data.</Highlight> The right to access, update or delete the
                information We have on You. Whenever made possible, you can access, update or request deletion of
                Your Personal Data directly within Your account settings section.
                If you are unable to perform these actions yourself, please contact Us to assist You.
                This also enables You to receive a copy of the Personal Data We hold about You.
              </li>
              <li>
                <Highlight>Request correction of the Personal Data that We hold about You.</Highlight> You have the right
                to have any incomplete or inaccurate information We hold about You corrected.
              </li>
              <li>
                <Highlight>Object to processing of Your Personal Data.</Highlight> This right exists where We are relying
                on a legitimate interest as the legal basis for Our processing and there is something about Your
                particular situation, which makes You want to object to our processing of Your Personal Data on this ground.
                You also have the right to object where We are processing Your Personal Data for direct marketing purposes.
              </li>
              <li>
                <Highlight>Request erasure of Your Personal Data.</Highlight> You have the right to ask Us to delete or
                remove Personal Data when there is no good reason for Us to continue processing it.
              </li>
              <li>
                <Highlight>Request the transfer of Your Personal Data.</Highlight> We will provide to You, or to a
                third-party You have chosen, Your Personal Data in a structured, commonly used, machine-readable format.
                Please note that this right only applies to automated information which You initially provided consent
                for Us to use or where We used the information to perform a contract with You.
              </li>
              <li>
                <Highlight>Withdraw Your consent.</Highlight> You have the right to withdraw Your consent on using your
                Personal Data. If You withdraw Your consent, We may not be able to provide You with access to certain
                specific functionalities of the Service.
              </li>
            </PrivacyList>
            <Title variant="card" mt={4}>
              Exercising of Your GDPR Data Protection Rights
            </Title>
            <Text>
              You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us.
              Please note that we may ask You to verify Your identity before responding to such requests.
              If You make a request, We will try our best to respond to You as soon as possible.
            </Text>
            <Text mt={3}>
              You have the right to complain to a Data Protection Authority about Our collection and use of Your
              Personal Data. For more information, if You are in the European Economic Area (EEA), please contact
              Your local data protection authority in the EEA.
            </Text>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default GDPR;
