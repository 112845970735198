import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Email, Section, Text, Title } from "../../components/Core";

const ContactUs = () => (
  <>
    <Section className="pt-0 pb-5">
      <Container>
        <Row className="justify-content-center mb-3">
          <Col>
            <Title variant="quote" className="mb-2">
              Contact Us
            </Title>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <Text>
              If you have any questions about this Privacy Policy, You can contact us:
            </Text>
            <Text mt={3}>
              By email: <Email address="privacy@sharptree.io" />
            </Text>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default ContactUs;
