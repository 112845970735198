import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Text } from "../Core";

import styled from "styled-components";
import { saturate, lighten } from "polished";

const SectionStyled = styled(Section)`
  background-image: ${({ theme }) => `radial-gradient(
    circle 961px at 49.94% 0%,
    ${lighten(0.114, saturate(0.0911, theme.colors.ash))} 0%,
    ${theme.colors.ash} 100%
  );`};
`;

const TitleContent = ({ title, children, color = "light", ...rest }) => {
  return (
    <>
      <div className="pt-5"></div>
      <Container>
        <Row className="justify-content-center text-center">
          <Col lg="8">
            <div className="banner-content">
              <Title variant="hero" color={color}>{title}</Title>
              <Text color={color} opacity={0.7}>
                {children}
              </Text>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const PageTitle = ({ titleDark, title, children, ...rest }) => {
  return titleDark
    ? (
      <SectionStyled>
        <TitleContent title={title} children={children} color="light" />
      </SectionStyled>
    )
    : (
      <Section>
        <TitleContent title={title} children={children} color="dark" />
      </Section>
    )
};

export default PageTitle;