import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Section, Title } from "../../../components/Core";

const CCPAIntro = () => (
  <>
    <Section className="pb-5" id="CCPA">
      <Container>
        <Row className="justify-content-center mb-5">
          <Col>
            <Title variant="quote" className="mb-2">
              California Consumer Privacy Act Privacy Rights
            </Title>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default CCPAIntro;
