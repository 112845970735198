import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Section, Text, Title } from "../../../components/Core";
import { PrivacyList, Highlight } from "../Common";

const CCPADefinitions = () => (
  <>
    <Section className="pt-0 pb-5">
      <Container>
        <Row className="justify-content-center mb-3">
          <Col>
            <Title variant="quote" className="mb-2">
              Interpretation and Definitions
            </Title>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <Title variant="card">
              Interpretation
            </Title>
            <Text>
              The words of which the initial letter is capitalized have meanings defined under the following conditions.
              The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
            </Text>
            <Title variant="card" mt={4}>
              Definitions
            </Title>
            <Text>
              For the purposes of this Privacy Policy:
            </Text>
            <PrivacyList>
              <li><Highlight>Account</Highlight> means a unique account created for You to access our Service or parts of our Service.</li>
              <li>
                <Highlight>Affiliate</Highlight> means an entity that controls, is controlled by or is under common control with a party,
                where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for
                election of directors or other managing authority.
              </li>
              <li>
                <Highlight>Application</Highlight> means the software program provided by the Company downloaded by You on any
                electronic device, named Opqo.
              </li>
              <li>
                <Highlight>Business,</Highlight> for the purpose of the CCPA (California Consumer Privacy Act), refers to the
                Company as the legal entity that collects Consumers' personal information and determines the purposes and means
                of the processing of Consumers' personal information, or on behalf of which such information is collected and
                that alone, or jointly with others, determines the purposes and means of the processing of consumers'
                personal information, that does business in the State of California.
              </li>
              <li>
                <Highlight>Company</Highlight> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement)
                refers to SharpTree LLC, 6563 California Ave #389, Seattle WA 98136.
              </li>
              <li>
                <Highlight>Consumer,</Highlight> for the purpose of the CCPA (California Consumer Privacy Act),
                means a natural person who is a California resident. A resident, as defined in the law, includes
                (1) every individual who is in the USA for other than a temporary or transitory purpose, and
                (2) every individual who is domiciled in the USA who is outside the USA for a temporary or transitory purpose.
              </li>
              <li><Highlight>Country</Highlight> refers to: United States</li>
              <li>
                <Highlight>Device</Highlight> means any device that can access the Service such as a computer,
                a cellphone or a digital tablet.
              </li>
              <li>
                <Highlight>Do Not Track</Highlight> (DNT) is a concept that has been promoted by US regulatory authorities,
                in particular the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement
                a mechanism for allowing internet users to control the tracking of their online activities across websites or applications.
              </li>
              <li>
                <Highlight>Personal Data</Highlight> is any information that relates to an identified or identifiable individual.<br />
                For the purposes of the CCPA, Personal Data means any information that identifies, relates to, describes or is
                capable of being associated with, or could reasonably be linked, directly or indirectly, with You.
              </li>
              <li>
                <Highlight>Sale,</Highlight> for the purpose of the CCPA (California Consumer Privacy Act), means selling,
                renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating
                orally, in writing, or by electronic or other means, a Consumer's personal information to another business
                or a third-party for monetary or other valuable consideration.
              </li>
              <li><Highlight>Service</Highlight> refers to the Opqo Application.</li>
              <li>
                <Highlight>Service Provider</Highlight> means any natural or legal person who processes the data on behalf of the
                Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service,
                to provide the Service on behalf of the Company, to perform services related to the Service or to assist the
                Company in analyzing how the Service is used.
              </li>
              <li>
                <Highlight>Third-party Social Media Service</Highlight> refers to any website or application or any social network
                Website or application through which a User can log in or create an account to use the Service.
              </li>
              <li>
                <Highlight>You (or Your)</Highlight> means the individual accessing or using the Service, or the company,
                or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
              </li>
              <li>
                <Highlight>Website</Highlight> means the website from which you clicked through to this Privacy Policy.
              </li>
            </PrivacyList>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default CCPADefinitions;
